import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { isEqual, isNil } from 'lodash-es';
import { distinctUntilChanged, map, Observable, Subscription } from 'rxjs';

import { DateFnsUtil } from '@stsm/date/util/timezone-safe-date-fns-util';
import { delayConditionally } from '@stsm/shared/util/rxjs.util';

import { systemMaintenanceBannerDelay, SystemMaintenanceInfo } from '../models/system-maintenance';

interface SystemMaintenanceState {
  systemMaintenanceInfo: SystemMaintenanceInfo;
}

const initialState: SystemMaintenanceState = {
  systemMaintenanceInfo: {},
};

@Injectable({ providedIn: 'root' })
export class SystemMaintenanceStore extends ComponentStore<SystemMaintenanceState> {
  readonly systemMaintenanceInfo$: Observable<SystemMaintenanceInfo> = this.select(
    (state: SystemMaintenanceState) => state.systemMaintenanceInfo,
  ).pipe(distinctUntilChanged(isEqual));

  isSystemMaintenanceOngoing$: Observable<boolean> = this.systemMaintenanceInfo$.pipe(
    map((info: SystemMaintenanceInfo) => info.isMaintenanceOngoing || false),
    distinctUntilChanged(),
  );

  showSystemMaintenanceBanner$: Observable<boolean> = this.systemMaintenanceInfo$.pipe(
    map((info: SystemMaintenanceInfo) => !isNil(info.plannedMaintenanceStart) && DateFnsUtil.isFuture(info.plannedMaintenanceStart)),
    distinctUntilChanged(),
    delayConditionally({ truthyDelay: systemMaintenanceBannerDelay, falsyDelay: 0 }),
  );

  readonly setSystemMaintenanceInfo: (systemMaintenanceInfo: SystemMaintenanceInfo) => Subscription = this.updater(
    (state: SystemMaintenanceState, systemMaintenanceInfo: SystemMaintenanceInfo) => {
      return {
        ...state,
        systemMaintenanceInfo,
      };
    },
  );

  constructor() {
    super(initialState);
  }
}
